
import TextInput from '@/components/forms/TextInput.vue';
import { defineComponent, onMounted, ref } from '@vue/runtime-core';
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import { RequestErrors } from '@/store/errors/RequestErrors';
import { userHasAccess } from "@/helpers/route";

export default defineComponent({
    components: { TextInput },
    setup() {
        const loading = ref(false)
        const notification = useNotification();
        const store = useStore();
        const router = useRouter();

        const details = ref({
            email: '',
            password: '',
        })

        const submit = async () => {
            loading.value = true;
            let { status, data } = await store.dispatch(Actions.LOGIN, details.value);
            if (status == 200) {
                loading.value = false;

                if(userHasAccess([],["dashboard.all"]) || userHasAccess(['sub-admin'],["dashboard.all"])){
                    window.location.href = "/dashboard"
                    return
                }
                window.location.href = "/vendors"
            }
            else {
                loading.value = false;
                notification.notify({
                    type: 'error',
                    title: "Authorization",
                    text: data.message
                })
            }
            loading.value = false;

        }

        onMounted(() => {
            store.commit('purge_user')
        })


        return {
            details, submit, loading
        }
    }
});



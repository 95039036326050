import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-auto min-h-[100vh] bg-[#bebebe12] w-[inherit] lg:ml-[280px] ml-0 mt-[90px] pt-0 pb-14" }
const _hoisted_2 = { class: "lg:w-[97%] md:w-[97%] w-[93%] rounded-lg mx-auto max-w-[1435px] mt-12 bg-white sm:px-10 px-0 pt-9 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBanners = _resolveComponent("AppBanners")!
  const _component_delivery_fee = _resolveComponent("delivery-fee")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppBanners, { bannerType: "app_carousel" }),
      _createVNode(_component_AppBanners, { bannerType: "vendor_carousel" }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_delivery_fee)
      ])
    ])
  ]))
}
<template>
    <div class="h-auto w-[inherit] lg:ml-[280px] md:ml-8 mt-[90px] pt-8">
        <section>
            <div class="lg:flex md:flex block justify-between items-center lg:pl-0 md:pl-0 pl-5 lg:pr-10  mb-5">
                <span> 
                    <p class="font-semibold text-xl">
                        Food Collections
                    </p>
                </span>
                <div class="flex lg:mt-0 md:mt-0 mt-6">
                    <div class="relative">
                        <select id="countries" class="food-select cursor-pointer bg-white border border-[#003087] focus:ring-1 focus:ring-[#003087] focus:border-0 focus:outline-none  rounded-xl text-gray-900 text-sm block lg:h-[55px] md:h-[55px] h-[48px] w-[125px] font-[500] pl-4">
                            <option selected>Food</option>
                            <option value="">Eat</option>
                            <option value="">Ride</option>
                            <option value="">Food</option>
                            <option value="">Food</option>
                        </select>
                     </div>
                    <div class="ml-5">
                        <input type="file" alt="file" class="hidden" id="new-upload" />
                        <label for="new-upload" class="sm:w-[210px] w-[170px] bg-[#003087] rounded-xl lg:h-[55px] md:h-[55px] h-[48px] flex justify-start items-center SM:pl-4 pl-2 cursor-pointer" id="new-uplaod">
                            <img src="../assets/images/file-plus.svg" class="w-[30px] h-[30px]" alt="file_plus" />
                        <p class="sm:text-sm text-xs text-white whitespace-nowrap sm:ml-3 ml-1" >Create new upload</p>
                        </label>
                    </div>

                </div>
            </div>
       </section>

       <section>
        <div class="lg:w-[97%] w-full lg:mx-0 mx-auto max-w-[1435px] mb-14">
            <div class="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 sm:px-0 px-5">
            <!----Row 1------>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <!----Row 2------>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <!----Row 3------>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            <div class="bg-[#FEF8F0] rounded-3xl h-[199px] w-full flex flex-col justify-start items-center pt-5">
                <div class="w-[90px] h-[90px] rounded-full border mb-5">
                    <img  src="../assets/images/food-pic.svg" class="w-full h-full" alt=""/>
                </div>
                <p class="text-[#252525] text-sm font-semibold mb-1">
                    Fry pasta & chic...
                </p>
                <p class="text-[#003087] text-lg font-semibold">
                    ₦750.00
                </p>
            </div>
            </div>
        </div>
       </section>
    </div>
</template>

<script>
export default {
    name: 'photos',
}
</script>

<style>
</style>
export default [
    {
        path: '',
        name: 'transaction_fees.index',
        component: ()=> import("@/views/transactionFee/Index.vue"),
        meta: {
            permissions: ["transaction_fees.index"],
        },
    },
    {
        path: 'create',
        name: 'transaction_fees.create',
        component: ()=> import("@/views/transactionFee/Create.vue"),
        meta: {
            permissions: ["transaction_fees.create"],
        },
    },
    {
        path: 'edit/:id',
        name: 'transaction_fees.edit',
        component: ()=> import("@/views/transactionFee/Edit.vue"),
        meta: {
            permissions: ["transaction_fees.edit"],
        },
    },
]
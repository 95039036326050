
export default [
    {
        path: '',
        name: 'vendors.index',
        component: ()=> import("@/views/vendors/vendors.vue"),
        meta: {
            permissions: ["vendor.index"],
        },
    },
    {
        path: 'view/:uuid',
        name: 'vendors.view',
        component: ()=> import("@/views/vendors/vendor.vue"),
        children: [
            {
                path: '',
                name: 'vendors.view.details',
                component: ()=> import("@/views/vendors/vendor-details.vue"),
                meta: {
                    permissions: ["vendor.view"],
                },
            },
            {
                path: 'orders',
                name: 'vendors.view.orders',
                component: ()=> import("@/views/vendors/vendor-orders.vue"),
                meta: {
                    permissions: ["vendor.view.orders.index"],
                },
            },
            {
                path: 'foods',
                name: 'vendors.view.foods',
                component: ()=> import("@/views/vendors/vendor-foods.vue"),
                meta: {
                    permissions: ["vendor.view.foods.index"],
                },
            },
            {
                path: 'transactions',
                name: 'vendors.view.transactions',
                component: ()=> import("@/views/vendors/vendor-transaction.vue"),
                meta: {
                    permissions: ["vendor.view.transactions.index"],
                },
            },
            {
                path: 'docs',
                name: 'vendors.view.docs',
                component: ()=> import("@/views/vendors/docs.vue"),
                meta: {
                    permissions: ["vendor.view.documents"],
                },
            }
        ]
    },
]
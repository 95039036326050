import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Order, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { useNotification } from "@kyvg/vue3-notification";
import { defaultOrder, defaultResponseMeta } from '@/ core/data/orderData';

interface OrderComposable {
    isLoading: Ref<boolean>,
    orders:Ref<Order[]>,
    order: Ref<Order>,
    getOrder: (uuid:any) => void;
    getOrders: ({status, uuid, model, page, search}:Params) => void;
    acceptOrder: (uuid:any) => void;
    rejectOrder: (uuid:any, note:string) => void;
    readyForPickup: (uuid:any) => void;
    paginationData: Ref<ResponseMeta>;
    getDeliveries: ({page, search}:Params) => void;
    assignRider: (data:any, uuid:any) => void;
    cancelOrder: (data:any, uuid:any) => void;
    completeOrder: (uuid:any) => void;
}

interface Params {
    status?: any,
    uuid?:any,
    model?:any,
    page?:number,
    search:string
}

export const useOrder = (): OrderComposable => {
    const isLoading = ref(false);
    const order: Ref<Order> = ref(cloneDeep(defaultOrder) as unknown as Order);
    const orders: Ref<Order[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getOrders = async ({status, uuid, model, page=1, search}:Params) => { 
        isLoading.value = true;

        let response = model != undefined ? await NetworkService.get('control-centre/get-orders/'+uuid+'?model='+model+'&page='+page+'&search='+search)
        : await NetworkService.get('control-centre/orders/?page='+page+'&'+status+'&search='+search)
        
        orders.value = response.data.data.map((e:any)=>{
            e.foods = JSON.parse(e.foods)
            return e;
        })
        
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        isLoading.value = false;
    }

    const getOrder = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('control-centre/orders/' + uuid);
        let data = response.data.data;

        data.foods =  JSON.parse(data.foods)
        data.flow =  JSON.parse(data.flow) 
        
        order.value = data;
        isLoading.value = false;
    }

    const acceptOrder = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.post('control-centre/order/' + uuid+'/accept', {});
        if(response.status ==200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    const rejectOrder = async (uuid:any, note: string) => {
        isLoading.value = true;
        let response = await NetworkService.post('control-centre/order/' + uuid+'/reject', {note});
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }
    
    const readyForPickup = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.post('control-centre/order/' + uuid+'/process', {});
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    const getDeliveries = async ({page=1, search}:Params) => { 
        isLoading.value = true
        let response = await NetworkService.get('control-centre/deliveries/?page='+page+'&search='+search)
        
        orders.value = response.data.data.map((e:any)=>{
            e.foods = JSON.parse(e.foods)
            return e;
        })
        
        
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta

        isLoading.value = false;
        
    }
    
    const assignRider = async (data:any, uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.post('control-centre/assign-rider', data);
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    const cancelOrder = async (data:any, uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.post('control-centre/order/cancel/' + uuid, data);
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    const completeOrder = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('control-centre/order/completed/' + uuid);
        if(response.status == 200){ await getOrder(uuid); }
        isLoading.value = false;
    }

    return {
        isLoading,
        order,
        orders,
        assignRider,
        paginationData,
        getOrders,
        acceptOrder,
        rejectOrder,
        readyForPickup,
        getOrder, 
        getDeliveries,
        cancelOrder,
        completeOrder                                                                                   
    }
}

import TextInput from '@/components/forms/TextInput.vue';
import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter } from "vue-router";
import { useForgotPassword } from "../../ core/composables/useForgotPassword";
import { useNotification } from "@kyvg/vue3-notification";
export default defineComponent({
    components: {
        TextInput
    },
    setup() {
        const notification = useNotification();
        const retry = ref(false);
        const email = ref('aladesiunpelumii@gmail.com');
        const count = ref(20);
        const { loading, sendEmail } = useForgotPassword();

        // retry sending mail after 20 secs. 
        const retrySend = () => {
            let timer = setInterval(() => { count.value-- }, 1000);
            setTimeout(() => {
                retry.value = false;
                clearInterval(timer);
                count.value = 20;
            }, 20000);
        }

        const send = async () => {
            let response = await sendEmail(email.value);

            if (response) {
                notification.notify({
                    type: response.status,
                    title: "Authorization",
                    text: response.message,
                })
                
                // start 20secs counting if the request was successful
                if(response.status == 'success'){
                    retry.value = true;
                    retrySend()
                }
                
            }
        }
        return {
            count,
            retry,
            email,
            loading,
            send,
        }
    }
});



<template>
    <div class="w-full h-[100vh] fixed hidden top-0 bottom-0 inset-0 z-50  bg-[#00010178]" id="layer" @click="closeSidebar"></div>
    <div class=" mr-4 w-[280px] fixed lg:left-0 left-[-280px] top-0 h-[100vh] bg-white z-50 ease-in-out duration-200 border-r-[.5px] border-[#FEF8F0]" id="sidebar">
        <div class="navbar-logo flex lg:justify-end items-start pt-12 pr-4 lg:ml-0 ml-[17px]">
            <img src="../assets/images/rideeatLogo.svg" alt="Rideeat-logo" />
        </div>

        <div class=" mt-16" id="sidebar">
            <div class="h-[80vh] overflow-scroll ">
                <ul class="lg:pl-14 pl-2 pr-7 sidebar_ul">

                    <li class=" pl-3" v-if="userHasAccess(['super-admin', 'sub-admin'], ['dashboar.all'])">
                        <router-link :to="{ name: 'dashboard'}" class="nav-link" :key="$route.fullPath">
                            <span
                                class="flex justify-start items-center rounded-2xl pl-3 py-3.5 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    class="dash-icon">
                                    <path class="side-icon"
                                        d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"
                                        fill="#2C2E2F" />
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Dashboard</p>
                            </span>
                        </router-link>
                    </li>

                    <!-- <li class="pl-3">
                            <router-link :to="{name: 'photos'}" class="nav-link" :key="$route.fullPath">
                                <span class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Iconly/Bold/Image">
                                        <g id="Image">
                                            <path class="side-icon" id="Image_2" fill-rule="evenodd" clip-rule="evenodd" d="M7.66618 22H16.3328C19.7231 22 22 19.6219 22 16.0833V7.91672C22 4.37811 19.7231 2 16.3338 2H7.66618C4.2769 2 2 4.37811 2 7.91672V16.0833C2 19.6219 4.2769 22 7.66618 22ZM8.49886 11C7.12021 11 6 9.87827 6 8.5C6 7.12173 7.12021 6 8.49886 6C9.8765 6 10.9977 7.12173 10.9977 8.5C10.9977 9.87827 9.8765 11 8.49886 11ZM19.8208 14.934C20.1557 15.7926 19.9817 16.8246 19.6237 17.6749C19.1994 18.6863 18.3869 19.452 17.3632 19.7864C16.9087 19.935 16.432 20 15.9564 20H7.52864C6.68999 20 5.94788 19.7988 5.3395 19.4241C4.95839 19.1889 4.89102 18.646 5.17358 18.2941C5.6462 17.7059 6.11279 17.1156 6.5834 16.5201C7.48038 15.3808 8.08473 15.0506 8.75645 15.3406C9.02896 15.4603 9.30248 15.6398 9.58404 15.8297C10.3342 16.3395 11.377 17.0402 12.7506 16.2797C13.6906 15.7532 14.2358 14.8501 14.7106 14.0637L14.7185 14.0506C14.7522 13.9954 14.7855 13.9402 14.8187 13.8852C14.9783 13.6212 15.1357 13.3607 15.3138 13.1207C15.5371 12.8204 16.3646 11.8813 17.4366 12.5501C18.1194 12.9711 18.6936 13.5408 19.308 14.1507C19.5423 14.3839 19.7092 14.6491 19.8208 14.934Z" fill="#2C2E2F"/>
                                        </g>
                                        </g>
                                    </svg>
                                    <p class="grayText ml-3 text-base font-[500]">Photos</p>
                                </span>
                        </router-link>
                        </li> -->
                    <li class="pl-3" v-if="userHasAccess([], ['vendor.index'])">
                        <router-link :to="{ name: 'vendors.index' }" class="nav-link" :key="$route.fullPath" >
                            <span
                                class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Category">
                                        <g id="Category">
                                            <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Vendors</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['customer.index'])">
                        <router-link :to="{ name: 'customers.index' }" class="nav-link" :key="$route.fullPath">
                            <span
                                class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Category">
                                        <g id="Category">
                                            <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Customers</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['rider.index'])">
                        <router-link :to="{ name: 'riders.index' }" class="nav-link" :key="$route.fullPath">
                            <span
                                class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Category">
                                        <g id="Category">
                                            <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Riders</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['order.index'])">
                        <router-link :to="{ name: 'order.ongoing' }" class="nav-link" :key="$route.fullPath">
                            <span
                                class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Category">
                                        <g id="Category">
                                            <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Orders</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['delivery.index'])">
                        <router-link :to="{ name: 'deliveries.index' }" class="nav-link" :key="$route.fullPath">
                            <span
                                class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Category">
                                        <g id="Category">
                                            <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Deliveries</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['food.index'])">
                        <router-link :to="{ name: 'foods' }" class="nav-link" :key="$route.fullPath">
                            <span
                                class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Category">
                                        <g id="Category">
                                            <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Foods</p>
                            </span>
                        </router-link>
                    </li>
                    <!-- <li class="pl-3">
                            <router-link :to="{name: 'call-delivery'}" class="nav-link" :key="$route.fullPath">
                                <span class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path class="side-icon" d="M14.4183 5.48994C13.9423 5.402 13.5051 5.70579 13.4145 6.17048C13.3238 6.63516 13.6286 7.08884 14.0917 7.17978C15.4859 7.4516 16.5625 8.53085 16.8354 9.92989V9.93089C16.9131 10.3336 17.2676 10.6264 17.6759 10.6264C17.7307 10.6264 17.7855 10.6214 17.8412 10.6114C18.3043 10.5185 18.6091 10.0658 18.5185 9.60012C18.1111 7.51055 16.5027 5.89666 14.4183 5.48994Z" fill="#2C2E2F"/>
                                        <path  class="side-icon" d="M14.3561 2.00793C14.133 1.97595 13.9089 2.04191 13.7307 2.18381C13.5474 2.32771 13.4329 2.53557 13.408 2.76841C13.3552 3.23908 13.6948 3.66479 14.1649 3.71776C17.4065 4.07951 19.9262 6.60477 20.2907 9.85654C20.3395 10.2922 20.705 10.621 21.1412 10.621C21.174 10.621 21.2059 10.619 21.2388 10.615C21.4668 10.59 21.67 10.4771 21.8134 10.2972C21.9558 10.1174 22.0206 9.89351 21.9947 9.66467C21.5405 5.60746 18.4005 2.45862 14.3561 2.00793Z" fill="#2C2E2F"/>
                                        <path  class="side-icon" fill-rule="evenodd" clip-rule="evenodd" d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z" fill="#2C2E2F"/>
                                    </svg>
                                    <p class="grayText ml-3 text-base font-[500]">Call Delivery</p>
                                </span>
                            </router-link>
                        </li> -->
                    <!-- <li class="pl-3">
                        <router-link :to="{name: 'promotions'}" class="nav-link">
                            <span class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="side-icon" fill-rule="evenodd" clip-rule="evenodd" d="M5.33043 0.000427246H14.6694C18.0704 0.000427246 19.9904 1.92943 20.0004 5.33043V14.6704C20.0004 18.0704 18.0704 20.0004 14.6694 20.0004H5.33043C1.92943 20.0004 0.000427246 18.0704 0.000427246 14.6704V5.33043C0.000427246 1.92943 1.92943 0.000427246 5.33043 0.000427246ZM10.0494 15.8604C10.4804 15.8604 10.8394 15.5404 10.8794 15.1104V4.92043C10.9194 4.61043 10.7704 4.29943 10.5004 4.13043C10.2194 3.96043 9.87943 3.96043 9.61043 4.13043C9.33943 4.29943 9.19043 4.61043 9.21943 4.92043V15.1104C9.27043 15.5404 9.62943 15.8604 10.0494 15.8604ZM14.6504 15.8604C15.0704 15.8604 15.4294 15.5404 15.4804 15.1104V11.8304C15.5094 11.5094 15.3604 11.2104 15.0894 11.0404C14.8204 10.8704 14.4804 10.8704 14.2004 11.0404C13.9294 11.2104 13.7804 11.5094 13.8204 11.8304V15.1104C13.8604 15.5404 14.2194 15.8604 14.6504 15.8604ZM6.21943 15.1104C6.17943 15.5404 5.82043 15.8604 5.38943 15.8604C4.95943 15.8604 4.59943 15.5404 4.56043 15.1104V8.20043C4.53043 7.88943 4.67943 7.58043 4.95043 7.41043C5.21943 7.24043 5.56043 7.24043 5.83043 7.41043C6.09943 7.58043 6.25043 7.88943 6.21943 8.20043V15.1104Z" fill="#130F26"/>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Promotions</p>
                            </span>
                        </router-link>
                    </li> -->
                    <li class="pl-3" v-if="userHasAccess([], ['transaction.index'])">
                        <router-link :to="{ name: 'transactions.index' }" class="nav-link">
                            <span
                                class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Activity">
                                        <g id="Activity">
                                            <path class="side-icon" id="Activity_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M17.1798 4.41C17.1798 3.08 18.2598 2 19.5898 2C20.9198 2 21.9998 3.08 21.9998 4.41C21.9998 5.74 20.9198 6.82 19.5898 6.82C18.2598 6.82 17.1798 5.74 17.1798 4.41ZM13.3297 14.7593L16.2197 11.0303L16.1797 11.0503C16.3397 10.8303 16.3697 10.5503 16.2597 10.3003C16.1507 10.0503 15.9097 9.8803 15.6507 9.8603C15.3797 9.8303 15.1107 9.9503 14.9497 10.1703L12.5307 13.3003L9.75969 11.1203C9.58969 10.9903 9.38969 10.9393 9.18969 10.9603C8.99069 10.9903 8.81069 11.0993 8.68969 11.2593L5.73069 15.1103L5.66969 15.2003C5.49969 15.5193 5.57969 15.9293 5.87969 16.1503C6.01969 16.2403 6.16969 16.3003 6.33969 16.3003C6.57069 16.3103 6.78969 16.1893 6.92969 16.0003L9.43969 12.7693L12.2897 14.9103L12.3797 14.9693C12.6997 15.1393 13.0997 15.0603 13.3297 14.7593ZM15.4497 3.7803C15.4097 4.0303 15.3897 4.2803 15.3897 4.5303C15.3897 6.7803 17.2097 8.5993 19.4497 8.5993C19.6997 8.5993 19.9397 8.5703 20.1897 8.5303V16.5993C20.1897 19.9903 18.1897 22.0003 14.7897 22.0003H7.40069C3.99969 22.0003 1.99969 19.9903 1.99969 16.5993V9.2003C1.99969 5.8003 3.99969 3.7803 7.40069 3.7803H15.4497Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Transactions</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['location.index'])">
                        <router-link :to="{ name: 'location.index' }" class="nav-link">
                            <span
                                class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Bold/Activity">
                                        <g id="Activity">
                                            <path class="side-icon" id="Activity_2" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M17.1798 4.41C17.1798 3.08 18.2598 2 19.5898 2C20.9198 2 21.9998 3.08 21.9998 4.41C21.9998 5.74 20.9198 6.82 19.5898 6.82C18.2598 6.82 17.1798 5.74 17.1798 4.41ZM13.3297 14.7593L16.2197 11.0303L16.1797 11.0503C16.3397 10.8303 16.3697 10.5503 16.2597 10.3003C16.1507 10.0503 15.9097 9.8803 15.6507 9.8603C15.3797 9.8303 15.1107 9.9503 14.9497 10.1703L12.5307 13.3003L9.75969 11.1203C9.58969 10.9903 9.38969 10.9393 9.18969 10.9603C8.99069 10.9903 8.81069 11.0993 8.68969 11.2593L5.73069 15.1103L5.66969 15.2003C5.49969 15.5193 5.57969 15.9293 5.87969 16.1503C6.01969 16.2403 6.16969 16.3003 6.33969 16.3003C6.57069 16.3103 6.78969 16.1893 6.92969 16.0003L9.43969 12.7693L12.2897 14.9103L12.3797 14.9693C12.6997 15.1393 13.0997 15.0603 13.3297 14.7593ZM15.4497 3.7803C15.4097 4.0303 15.3897 4.2803 15.3897 4.5303C15.3897 6.7803 17.2097 8.5993 19.4497 8.5993C19.6997 8.5993 19.9397 8.5703 20.1897 8.5303V16.5993C20.1897 19.9903 18.1897 22.0003 14.7897 22.0003H7.40069C3.99969 22.0003 1.99969 19.9903 1.99969 16.5993V9.2003C1.99969 5.8003 3.99969 3.7803 7.40069 3.7803H15.4497Z"
                                                fill="#2C2E2F" />
                                        </g>
                                    </g>
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Locations</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['setting.index'])">
                        <router-link :to="{ name: 'settings' }" class="nav-link">
                            <span
                                class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="side-icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M20.4022 13.5801C20.7599 13.7701 21.0358 14.0701 21.23 14.3701C21.6081 14.9901 21.5775 15.7501 21.2096 16.4201L20.4942 17.6201C20.1161 18.2601 19.4109 18.6601 18.6853 18.6601C18.3277 18.6601 17.9291 18.5601 17.6021 18.3601C17.3364 18.1901 17.0298 18.1301 16.7027 18.1301C15.691 18.1301 14.8428 18.9601 14.8121 19.9501C14.8121 21.1001 13.8719 22.0001 12.6967 22.0001H11.3068C10.1213 22.0001 9.18113 21.1001 9.18113 19.9501C9.16069 18.9601 8.31247 18.1301 7.30073 18.1301C6.96348 18.1301 6.6569 18.1901 6.40141 18.3601C6.07438 18.5601 5.6656 18.6601 5.31813 18.6601C4.58232 18.6601 3.87717 18.2601 3.49905 17.6201L2.7939 16.4201C2.41577 15.7701 2.39533 14.9901 2.77346 14.3701C2.93697 14.0701 3.24356 13.7701 3.59102 13.5801C3.87717 13.4401 4.06112 13.2101 4.23486 12.9401C4.74584 12.0801 4.43925 10.9501 3.57059 10.4401C2.55885 9.87012 2.23182 8.60012 2.81434 7.61012L3.49905 6.43012C4.09178 5.44012 5.35901 5.09012 6.38097 5.67012C7.27007 6.15012 8.42488 5.83012 8.94608 4.98012C9.10959 4.70012 9.20157 4.40012 9.18113 4.10012C9.16069 3.71012 9.27311 3.34012 9.46728 3.04012C9.8454 2.42012 10.5301 2.02012 11.2761 2.00012H12.7171C13.4734 2.00012 14.1581 2.42012 14.5362 3.04012C14.7201 3.34012 14.8428 3.71012 14.8121 4.10012C14.7917 4.40012 14.8837 4.70012 15.0472 4.98012C15.5684 5.83012 16.7232 6.15012 17.6225 5.67012C18.6342 5.09012 19.9117 5.44012 20.4942 6.43012L21.1789 7.61012C21.7716 8.60012 21.4446 9.87012 20.4227 10.4401C19.554 10.9501 19.2474 12.0801 19.7686 12.9401C19.9321 13.2101 20.1161 13.4401 20.4022 13.5801ZM9.10959 12.0101C9.10959 13.5801 10.4075 14.8301 12.012 14.8301C13.6164 14.8301 14.8837 13.5801 14.8837 12.0101C14.8837 10.4401 13.6164 9.18012 12.012 9.18012C10.4075 9.18012 9.10959 10.4401 9.10959 12.0101Z"
                                        fill="#2C2E2F" />
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Settings</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['subscription.index'])">
                        <router-link :to="{ name: 'subscription.index' }" class="nav-link">
                            <span
                                class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="side-icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M20.4022 13.5801C20.7599 13.7701 21.0358 14.0701 21.23 14.3701C21.6081 14.9901 21.5775 15.7501 21.2096 16.4201L20.4942 17.6201C20.1161 18.2601 19.4109 18.6601 18.6853 18.6601C18.3277 18.6601 17.9291 18.5601 17.6021 18.3601C17.3364 18.1901 17.0298 18.1301 16.7027 18.1301C15.691 18.1301 14.8428 18.9601 14.8121 19.9501C14.8121 21.1001 13.8719 22.0001 12.6967 22.0001H11.3068C10.1213 22.0001 9.18113 21.1001 9.18113 19.9501C9.16069 18.9601 8.31247 18.1301 7.30073 18.1301C6.96348 18.1301 6.6569 18.1901 6.40141 18.3601C6.07438 18.5601 5.6656 18.6601 5.31813 18.6601C4.58232 18.6601 3.87717 18.2601 3.49905 17.6201L2.7939 16.4201C2.41577 15.7701 2.39533 14.9901 2.77346 14.3701C2.93697 14.0701 3.24356 13.7701 3.59102 13.5801C3.87717 13.4401 4.06112 13.2101 4.23486 12.9401C4.74584 12.0801 4.43925 10.9501 3.57059 10.4401C2.55885 9.87012 2.23182 8.60012 2.81434 7.61012L3.49905 6.43012C4.09178 5.44012 5.35901 5.09012 6.38097 5.67012C7.27007 6.15012 8.42488 5.83012 8.94608 4.98012C9.10959 4.70012 9.20157 4.40012 9.18113 4.10012C9.16069 3.71012 9.27311 3.34012 9.46728 3.04012C9.8454 2.42012 10.5301 2.02012 11.2761 2.00012H12.7171C13.4734 2.00012 14.1581 2.42012 14.5362 3.04012C14.7201 3.34012 14.8428 3.71012 14.8121 4.10012C14.7917 4.40012 14.8837 4.70012 15.0472 4.98012C15.5684 5.83012 16.7232 6.15012 17.6225 5.67012C18.6342 5.09012 19.9117 5.44012 20.4942 6.43012L21.1789 7.61012C21.7716 8.60012 21.4446 9.87012 20.4227 10.4401C19.554 10.9501 19.2474 12.0801 19.7686 12.9401C19.9321 13.2101 20.1161 13.4401 20.4022 13.5801ZM9.10959 12.0101C9.10959 13.5801 10.4075 14.8301 12.012 14.8301C13.6164 14.8301 14.8837 13.5801 14.8837 12.0101C14.8837 10.4401 13.6164 9.18012 12.012 9.18012C10.4075 9.18012 9.10959 10.4401 9.10959 12.0101Z"
                                        fill="#2C2E2F" />
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Subscriptions</p>
                            </span>
                        </router-link>
                    </li>
                    <li class="pl-3" v-if="userHasAccess([], ['transaction_fees.index'])">
                        <router-link :to="{ name: 'transaction_fees.index' }" class="nav-link">
                            <span
                                class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="side-icon" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M20.4022 13.5801C20.7599 13.7701 21.0358 14.0701 21.23 14.3701C21.6081 14.9901 21.5775 15.7501 21.2096 16.4201L20.4942 17.6201C20.1161 18.2601 19.4109 18.6601 18.6853 18.6601C18.3277 18.6601 17.9291 18.5601 17.6021 18.3601C17.3364 18.1901 17.0298 18.1301 16.7027 18.1301C15.691 18.1301 14.8428 18.9601 14.8121 19.9501C14.8121 21.1001 13.8719 22.0001 12.6967 22.0001H11.3068C10.1213 22.0001 9.18113 21.1001 9.18113 19.9501C9.16069 18.9601 8.31247 18.1301 7.30073 18.1301C6.96348 18.1301 6.6569 18.1901 6.40141 18.3601C6.07438 18.5601 5.6656 18.6601 5.31813 18.6601C4.58232 18.6601 3.87717 18.2601 3.49905 17.6201L2.7939 16.4201C2.41577 15.7701 2.39533 14.9901 2.77346 14.3701C2.93697 14.0701 3.24356 13.7701 3.59102 13.5801C3.87717 13.4401 4.06112 13.2101 4.23486 12.9401C4.74584 12.0801 4.43925 10.9501 3.57059 10.4401C2.55885 9.87012 2.23182 8.60012 2.81434 7.61012L3.49905 6.43012C4.09178 5.44012 5.35901 5.09012 6.38097 5.67012C7.27007 6.15012 8.42488 5.83012 8.94608 4.98012C9.10959 4.70012 9.20157 4.40012 9.18113 4.10012C9.16069 3.71012 9.27311 3.34012 9.46728 3.04012C9.8454 2.42012 10.5301 2.02012 11.2761 2.00012H12.7171C13.4734 2.00012 14.1581 2.42012 14.5362 3.04012C14.7201 3.34012 14.8428 3.71012 14.8121 4.10012C14.7917 4.40012 14.8837 4.70012 15.0472 4.98012C15.5684 5.83012 16.7232 6.15012 17.6225 5.67012C18.6342 5.09012 19.9117 5.44012 20.4942 6.43012L21.1789 7.61012C21.7716 8.60012 21.4446 9.87012 20.4227 10.4401C19.554 10.9501 19.2474 12.0801 19.7686 12.9401C19.9321 13.2101 20.1161 13.4401 20.4022 13.5801ZM9.10959 12.0101C9.10959 13.5801 10.4075 14.8301 12.012 14.8301C13.6164 14.8301 14.8837 13.5801 14.8837 12.0101C14.8837 10.4401 13.6164 9.18012 12.012 9.18012C10.4075 9.18012 9.10959 10.4401 9.10959 12.0101Z"
                                        fill="#2C2E2F" />
                                </svg>
                                <p class="grayText ml-3 text-base font-[500]">Transaction Fee</p>
                            </span>
                        </router-link>
                    </li>
                </ul>
            
                <div class="pl-2 lg:pl-16 pr-7 mt-4  cursor-pointer">
                    <span
                        class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                        <img src="../assets/images/Logout.svg" alt="Dashboard" />
                        <p class="ml-3 text-base font-[500] text-[#FF1E54]" @click="signOut">Log out</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import { Actions } from "@/store/enums/StoreEnums";
import { computed, defineComponent, watch } from "@vue/runtime-core"
import { useStore } from "vuex";
import { useRouter, useRoute, Router } from "vue-router";
import { useToggle } from "@/ core/composables/useToggle";
import { userHasAccess } from "@/helpers/route";

export default defineComponent({
    name: 'sidebar',

    setup() {

        const { open: openSideBar, close: closeSidebar, } = useToggle('sidebar', 'layer');
        const store = useStore();
        const router = useRouter();
        const route = useRoute();


        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "login" });
            });
        };

        watch(
            () => route.path,
            (to, from) => {
                closeSidebar()
            }
        );
        return { signOut, closeSidebar, userHasAccess }

    }
});
</script>

<style scoped></style>

import NetworkService from '@/ core/services/network-service';
import { defineComponent, onMounted, ref } from 'vue';
import BarChart from './BarChart.vue';
import { on } from 'events';
import type { DashboardDetails } from '@/models';
import OrdersTable from './orders/orders-table.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';
import DashboardDetailsCard from '@/components/DashboardDetailsCard.vue';

export default defineComponent({
  name: "dashboard",
  components: {
    BarChart,
    OrdersTable,
    NumberFormatter,
    DashboardDetailsCard,
  },
  setup() {
    const dashboardDetails = ref({} as unknown as DashboardDetails);
    const loading = ref(false);

    const getDashboardDetails = async () => {
      loading.value = true;

      const response = await NetworkService.get('control-centre/details')
      dashboardDetails.value = response.data
      loading.value = false;
    }

    onMounted(() => {
      getDashboardDetails();
    })

    return {
      dashboardDetails,
      loading
    }
  }
});


import { useRouter } from "vue-router";

export default {
  name: "unauthorized",
  setup() {
    const router = useRouter();

    const back = () => {
      router.back();
    }

    return {
      back
    };
  },
};

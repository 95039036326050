<template>
    <div class="h-auto min-h-[100vh] bg-[#bebebe12] w-[inherit] lg:ml-[280px]  ml-0 pt-[200px] ">
        <div class="lg:w-[97%] md:w-[97%] w-[93%] rounded-lg mx-auto max-w-[1435px] bg-white sm:px-10 px-5 pt-9 pb-4 ">
            <form @submit.prevent="submitNewAddress">
            <p class="font-semibold text-xl">Edit Address</p>

                <div class="grid md:grid-cols-2 grid-cols-1 gap-5 mt-5">
                    <div>
                        <label for="city" class="text-sm my-1">City</label>
                        <select v-model="location.city" id="city" required
                            class="bg-white border edit_select active:border-[#b7b7b7] border-[#d1d5db] text-[#1E2234] font-[Inter] text-base rounded-[5px] px-3 focus:ring-[#b7b7b7] focus:border-[#b7b7b7] block w-full md:h-[52px] h-[48px] food_input placeholder:text-[#B6B6B6] placeholder:font-[400] placeholder:text-base"
                            data-te-select-init>
                            <option v-for="(states, idx) in statesAndLga" :key="idx" :value="idx == 0 ? null : idx" :disabled="idx == 0" > {{ states.state }} </option>
                        </select>
                    </div>
                    <div>
                        <label for="lga" class="text-sm my-1">LGA</label>
                        <select v-model="location.local_government" required id="lga"
                            class="bg-white border active:border-[#b7b7b7] border-[#d1d5db] text-[#1E2234] font-[Inter] text-base edit_select rounded-[5px] pl-3 focus:ring-[#b7b7b7] focus:border-[#b7b7b7] block w-full md:h-[52px] h-[48px] food_input placeholder:text-[#B6B6B6] placeholder:font-[400] placeholder:text-base"
                            data-te-select-init :disabled="location.city == 0">
                            <option v-for="(lga, idx) in statesAndLga[location.city].lgas" :key="idx" :value="lga" > {{ lga }} </option>
                        </select>
                    </div>
                </div>

                <div class="grid grid-cols-1  md:mt-10 mt-5 mb-10">
                    <label for="street" class="text-sm my-1">Street address</label>
                    <input type="text" v-model="location.address" required id="street"
                        class="bg-white border active:border-[#b7b7b7] border-[#d1d5db] text-[#1E2234] font-[Inter] text-base rounded-[5px] px-3 focus:ring-[#b7b7b7] focus:border-[#b7b7b7] block w-full h-[52px] food_input placeholder:text-[#B6B6B6] placeholder:font-[400] placeholder:text-base"
                        placeholder="Address" />
                </div>

                <div>
                    <button
                        class="btn-filled text-sm h-[50px] sm:w-[170px] w-full rounded-lg block mr-auto bg-[#003087] hover:bg-white hover:border hover:border-[#003087] hover:text-[#003087]   my-4 md:mb-[20px] text-center text-white">
                        Submit</button>
                </div>
            </form> 
        </div>
  
        <div>

        </div>
    </div>
</template>

<script >
import statesAndLgas from '../config/data/state_lga.json';
import { ref, watch } from 'vue';
import NetworkService from '@/ core/services/network-service';
import {  useNotification } from "@kyvg/vue3-notification";
export default {
    name: 'edit-address',
    components: {
    },
    setup() {
        const notification = useNotification();

        const location = ref({
            city: 0,
            local_government: '',
            address: ''
        });

        const processPayload = () => { 
            let payload = {
                businessInfo: {
                    city: statesAndLgas[location.value.city].state,
                    local_government: location.value.local_government,
                    address: location.value.address
                }
            }
            return payload;
        }

        watch(location.value, () => processPayload());

        const statesAndLga = statesAndLgas;

        const submitNewAddress = async () => {
            let response = await NetworkService.post('/vendor/update-address', processPayload());
            if (response.status == 200) {
                notification.notify({
                    type: 'success',
                    title: "Update Profile",
                    text: response.data.message,
                })
                return;
            }
            notification.notify({
                    type: 'error',
                    title: "Update Profile",
                    text: response.data.message,
                })
        }

        return {
            location,
            statesAndLga,
            submitNewAddress,
        }
    }
}
</script>
<style scoped></style>


import { defineComponent, onMounted, ref } from 'vue';
import ordersTable from '@/views/orders/orders-table.vue';

export default defineComponent({
  components: { ordersTable },
    setup() {
        
    }
})


import HandleDocument from "@/components/HandleDocument.vue";
import { ref } from "vue";
import NetworkService from "@/ core/services/network-service";
import { useNotification } from "@kyvg/vue3-notification";

export default {
  name: "uplaod-image",
  components: {
    HandleDocument,
  },
  setup() {
    const notification = useNotification();

    const logo = ref({
      logo_image: "",
      banner_image: "",
    });

    const myLogo = (fileResponse: any) => {
      logo.value.logo_image = fileResponse.url;
    };

    const myBanner = (fileResponse: any) => {
      logo.value.banner_image = fileResponse.url;
    };

    const uploadImage = async () => {

      if (logo.value.banner_image == '' && logo.value.logo_image == '') {
        notification.notify({
          type: 'warn',
          title: "Update Profile",
          text: 'please select a valid file ',
        })
        return;
      }

      let response = await NetworkService.post("/vendor/update-logo-and-banner", logo.value);

      if (response.status == 200) {
        notification.notify({
          type: 'success',
          title: "Update Profile",
          text: response.data.message,
        })
        return;
      } 
      notification.notify({
          type: 'error',
          title: "Update Profile",
          text: response.data.message,
        })
    };

    return {
      myLogo,
      myBanner,
      uploadImage,
      logo,
    };
  },
};


import { defineComponent, onMounted, ref, computed, PropType } from "vue";
import type { Header, Item, ClickRowArgument } from "vue3-easy-data-table";
import { Location } from '@/models';
import { useLocation } from '@/ core/composables/useLocation';
import { useRouter } from "vue-router";
import NumberFormatter from "@/components/NumberFormatter.vue";
import Spinner from "@/components/Spinner.vue";
import LocationModal from "@/views/location/LocationModal.vue";

export default defineComponent({
    name: "customers-table",
    setup() {
        const router = useRouter();
        const { locations, getLocations, paginationData, isLoading, create } = useLocation();
        const headers = [
            { text: "Country", value: "country" },
            { text: "State", value: "state" },
            { text: "Town", value: "town" },
            { text: "Areas", value: "areas" }
        ];

        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const items = ref([] as unknown as Location[]);
        const search = ref("")

        const fetchLocations = async (page?: number) => {
            await getLocations(page, search.value);

            items.value = locations.value;
            

            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
        };

        onMounted(async () => {
            fetchLocations();

            // const originAutoComplete = new google.maps.places.autocomplete
        });

        const searchMethod = () => {
            fetchLocations(1)
        }


        const onClickRow = (row: ClickRowArgument) => {
            router.push({ name: "location.view", params: { id: row.id } });
        };

        const nextPage = (page: number) => {
            fetchLocations(page);
        };

        const prevPage = (page: number) => {
            fetchLocations(page);
        };

        const addAddress = ( async (address: Object) => {
            await create(address)
            fetchLocations();
        })


        return {
            headers,
            items,
            paginationData,
            isFirstPage,
            isLastPage,
            nextPage,
            prevPage,
            next,
            prev,
            onClickRow,
            searchMethod,
            search,
            isLoading,
            addAddress
        };
    },
    components: { NumberFormatter, Spinner, LocationModal }
});


import { defineComponent, onMounted, ref, computed } from "vue";
import OrdersTable from "@/views/deliveries/deliveries-table.vue";


export default defineComponent({
    name: "orders",
    setup() {
        
    },
    components: { OrdersTable }
});

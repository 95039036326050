import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { User, Food, ResponseMeta } from "@/models";
import { defaultResponseMeta } from '@/ core/data/orderData';
import { cloneDeep } from "lodash";

interface FoodComposable {
    getFoods:({page, uuid, search}:Params )=> void,
    getFood:(uuId:any)=> void,
    foods: Ref<[Food]>,
    food: Ref<Food>,
    loading: Ref<Boolean>,
    paginationData: Ref<ResponseMeta>;
}
interface Params {
    uuid?:any,
    page?:number,
    search?:any
}

export const useFood = ():FoodComposable =>{
    const foods = ref();
    const food = ref();
    const loading = ref(true);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getFoods = async ({page = 1, uuid, search}:Params,) =>  {
        let response = uuid? await NetworkService.get('/control-centre/vendor/foods/'+uuid+'/?page='+page+'&search='+search):
        await NetworkService.get('/control-centre/foods?page='+page+'&search='+search);
       
       loading.value= false;
       foods.value = response.data.data;

       let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
    }
    
    const getFood = async (uuId:any) =>  {

        let response =  await NetworkService.get('/control-centre/foods/'+uuId);
        
        if (response) {
            loading.value= false;
            food.value = response.data.data;
        }
        
     }

    return {
        getFood,
        loading,
        getFoods,
        foods,
        food,
        paginationData
    }

}
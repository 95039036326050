export const defaultOrder = {
    delivery_to: "",
    to_coordinate: "",
    delivery_from: "",
    from_coordinate: "",
    delivery_fee: null,
    duration: null,
    delivery_distance: "",
    total: null,
    sub_total: null,
    delivery_method: "",
    foods: {},
    note: "",
    status: null,
    flow: {},
    code: null,
}

export const defaultResponseMeta = {
    per_page: null,
    total: null,
    last_page: null,
    current_page: null,
    next: null,
    prev: null,
    first: null,
    last: null,
    from: null,
    path: null,
    to: null,
}

export const defaultVendor = {
    seller_id: null,
    email: "",
    store_name: "",
    ranking: null,
    longitude: null,
    latitude: null,
    street_null: "",
    address: "",
    town: "",
    state: "",
    area: "",
    country: "",
    logo_image: "",
    banner_image: "",
    status: "",
    phone: "",
    info: {
        firstname: "",
        lastname: "",
        phone: ""
    }
}

export const defaultRider = {
    email: "",
    address: "",
    city: "",
    country: "",
    lattitude: "",
    longitude: "",
    local_government: "",
    phone: "",
    store_name: "",
    street_name: "",
    status: "",
    routes: "",
    info: {
        firstname: "",
        lastname: "",
        phone: ""
    }
}

export const defaultCustomer =  {
    balance: 0, 
    created_at: "",
    id: 0,
    model: "",
    model_id: 0,
    updated_at: "",
    user:{
        uuid: "",
        id: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
        gender: "",
        phone_verified: null,
        device_id: "",
        email_verified: "",
        status: "",
    }
}

export const defaultTransaction = {
    wallet_id: null,
    resource_id: null,
    description: "",
    balance_before: null,
    balance_after: null,
    amount: null,
    type: "",
    extra: null,
    wallet: {}
}

export const defaultLocation = {
    id: null,
    areas: {
        title: ''
    },
    states: {
        title: ''
    },
    title: ''
}

export const defaultBanner = {
    action: null,
    customName: '',
    name: '',
    url: '',
    value: '',
    title: ''
}


export const defaultSubscription = {
    name: '',
    identifier: '',
    resource: '',
    quotas: null,
    expires_at: '',
    expires_after_day: null,
    publish: false,
    price: null,
    description: null
}

export const defaultTransactionFee = {
    vendor_uuid: "",
    model: "",
    model_id: null,
    flat_value: null,
    percentage_value: null,
    target: "",
}
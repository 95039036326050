<template>
    <div>
        <button type="button" @click="openModal = true"
            class="border bg-[#013087]  text-[14px] text-white rounded-[8px] p-3 " data-te-ripple-color="light">
            Add Location
        </button>
        <div>
            <div data-te-modal-init v-if="openModal"
                class="fixed top-0 left-0 z-[100]  h-full w-full overflow-y-auto overflow-x-hidden outline-none sidebar-layout"
                id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true"
                role="dialog">
                <div data-te-modal-dialog-ref
                    class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center  transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[660px]">
                    <div
                        class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
                        <div
                            class="flex flex-shrink-0 items-center justify-end rounded-t-md  border-neutral-100 border-opacity-100 p-4 pb-0 ">
                            <button type="button" @click="openModal = !openModal"
                                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-te-modal-dismiss aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="relative p-6 pt-0">

                            <h3 class="text-lg font-semibold text-gray-900 mb-2 ">
                                Location
                            </h3>
                            <div class="w-full mb-4  relative">
                                <div class="grid lg:grid-cols-1 grid-cols-1   gap-2">
                                    <div class=" w-full g-wrap">
                                        <label for="address"
                                            class="block font-medium text-[#1E2234] text-[15px]">Address</label>
                                        <vue-google-autocomplete id="map"
                                            class="bg-white px-2 border border-[#d1d5db] text-[#1E2234] font-[Inter] text-base rounded-[5px] focus:ring-[#FF8900] focus:border-[#FF8900] block w-full h-[54px] food_input placeholder:text-[#B6B6B6] placeholder:font-[500] placeholder:text-sm"
                                            placeholder="Start typing" v-on:placechanged="getAddressData" ref="addr"
                                            country="ng">
                                        </vue-google-autocomplete>
                                        <div class="pt-5" v-if="address.country != ''">
                                            <div class="text-[15px] py-1">
                                                <span>Country:</span>
                                                <span class="pl-2"> {{ address.country }} </span>
                                            </div>
                                            <div class="text-[15px] py-1">
                                                <span>State:</span>
                                                <span class="pl-2"> {{ address.state }} </span>
                                            </div>
                                            <div class="text-[15px] py-1">
                                                <span>Town:</span>
                                                <span class="pl-2"> {{ address.town }} </span>
                                            </div>
                                            <div class="text-[15px] py-1">
                                                <span>Area:</span>
                                                <span class="pl-2"> {{ address.area }} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center items-center sm:mt-0 mt-7">
                                    <button type="button" @click.prevent="submit"
                                        class="mt-6 rounded-[6px] border-[1.5px] border-[#FF8900] sm:h-[47px] h-[45px] hover:bg-[#FF8900] hover:text-white font-[500] md:w-[327px] w-full text-[#FF8900] ease-in-out duration-200">
                                        Add Location
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/runtime-core"
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default defineComponent({
    components: { VueGoogleAutocomplete },
    emits: [
        'submitAddress'
    ],
    setup(props, { emit }) {
        const openModal = ref(false);
        const addr = ref();
        const address = ref({
            country: '',
            town: '',
            area: '',
            state: ''
        });
        const query = ref('');

        onMounted(() => {

        })

        const getAddressData = ((addressData, placeResultData, id) => {
            addr.value.focus()
            address.value = {
                area: addressData.route
            }

            const addressComponents = placeResultData.address_components;
            addressComponents.forEach((component) => {
                const types = component.types;
                if (types.includes('country')) {
                    address.value.country = component.short_name;
                } else if (types.includes('administrative_area_level_1')) {
                    address.value.state = component.long_name;
                } else if (types.includes('administrative_area_level_2')) {
                    address.value.town = component.long_name;
                } 

            });
        })

        const submit = (() => {
            emit('submitAddress', address.value)
            openModal.value = false
            
        })

        return {
            openModal,
            query,
            getAddressData,
            address,
            addr,
            submit
        }
    }
});
</script>
<style >
.g-wrap .pac-container {
    background-color: #FFF;
    z-index: 1055 !important;
    position: fixed;
    display: inline-block;
    float: left;
}
</style>

export default [
    {
        path: '',
        name: 'riders.index',
        component: ()=> import("@/views/riders/index.vue"),
        meta: {
            permissions: ["rider.index"],
        },
    },
    {
        path: 'view/:uuid',
        name: 'riders.view',
        component: ()=> import("@/views/riders/rider.vue"),
        children: [
            {
                path: '',
                name: 'riders.view.details',
                component: ()=> import("@/views/riders/rider-details.vue"),
                meta: {
                    permissions: ["rider.view"],
                },
            },
            {
                path: 'orders',
                name: 'riders.view.orders',
                component: ()=> import("@/views/riders/rider-orders.vue"),
                meta: {
                    permissions: ["rider.view.orders.index"],
                },
            },
            {
                path: 'transactions',
                name: 'riders.view.transactions',
                component: ()=> import("@/views/riders/rider-transactions.vue"),
                meta: {
                    permissions: ["rider.view.transactions.index"],
                },
            },
            {
                path: 'docs',
                name: 'riders.view.docs',
                component: ()=> import("@/views/riders/docs.vue"),
                meta: {
                    permissions: ["rider.view.documents"],
                },
            }
        ]
    },
]
<template>
    <div>
        <button type="button" @click="openModal = true"
            class="border bg-red-500  text-[12px] md:text-[14px] text-white rounded-[8px] p-3 " data-te-ripple-color="light">
            Cancel Order
        </button>
        <div>
            <div data-te-modal-init v-if="openModal"
                class="fixed top-0 left-0 z-[100]  h-full w-full overflow-y-auto overflow-x-hidden outline-none sidebar-layout"
                id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true"
                role="dialog">
                <div data-te-modal-dialog-ref
                    class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center  transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[660px]">
                    <div
                        class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
                        <div
                            class="flex flex-shrink-0 items-center justify-end rounded-t-md  border-neutral-100 border-opacity-100 p-4 pb-0 ">
                            <button type="button" @click="openModal = !openModal"
                                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-te-modal-dismiss aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="relative p-6 pt-0">

                            <h3 class="text-lg font-semibold text-gray-900 mb-2 capitalize ">
                               Cancel Order
                            </h3>
                            <div class="w-full mb-4  relative">
                                <div class=" w-full ">
                                        <label for="food-meal"
                                            class="block mb-1 font-medium text-[#1E2234] text-[15px]">Please give a reason you want to cancel this order</label>
                                        <textarea  v-model="data.thought"  class="bg-white resize-none border h-36 active:border-[#b7b7b7] border-[#d1d5db] text-[#1E2234] 
                                        py-3 text-base rounded-[5px] px-3 focus:ring-[#b7b7b7] focus:border-[#b7b7b7] block w-full price_input placeholder:text-[#B6B6B6] placeholder:font-[600] placeholder:text-base placeholder:text-center placeholder:text-[14px]">
                                        
                                    </textarea>
                                    </div>
                                <div class="flex justify-center items-center sm:mt-0 mt-7">
                                    <button type="button" @click.prevent="submit"
                                        class="mt-6 rounded-[6px] border-[1.5px] border-red-500 bg-red-500 sm:h-[47px] h-[45px] hover:bg-white hover:text-red-500 font-[500] md:w-[327px] w-full text-white ease-in-out duration-200">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/runtime-core"

export default defineComponent({
    emits: [
        'cancelOrder'
    ],
    setup(props, { emit }) {
        const openModal = ref(false);

        const data = ref({
            thought: ''
        });

        onMounted(() => {

        })

        const submit = (() => {
            emit('cancelOrder', data.value)
            openModal.value = false 
        })

        return {
            openModal,
            submit,
            data
        }
    }
});
</script>
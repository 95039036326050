
import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { useTransaction } from '@/ core/composables/useTransaction';
import Loader from '@/components/loader.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
    components: { TitledCard, Loader, NumberFormatter, Spinner },
    setup() {

        const { isLoading, transaction,
            getTransaction } = useTransaction();

        const uuId = useRoute().params.uuid;

        onMounted(async () => {
            await getTransaction(uuId);

        });


        return {
            transaction,
            isLoading,
        }
    }
})

<template>
    <div>
      <Navbar />
      <div class="sm:px-5"> 
        <router-view />
      </div>
      <sidebar />
    </div>
  </template>
  <script>
  import { computed, defineComponent, nextTick, onMounted } from "vue";
  import Navbar from '../components/Navbar.vue';
  import Sidebar from '../components/Sidebar.vue';
  
  export default defineComponent({
    name: 'Index',
    components: {
      Navbar,
      Sidebar
    },
    setup() {
     
    }
  })
  
  </script>
  
  
  <style>
  
  </style>
  
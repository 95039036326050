<template>
  <div>
    <router-view />
    <notifications :duration="5000" position="top right mt-[400px]"/>
    <!-- <ErrorNotification/> -->
  </div>
</template>
<script>
import { computed, defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ErrorNotification from "./components/ErrorNotification.vue";
export default defineComponent({
  name: 'App',
  components: {
    ErrorNotification
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      if (route.meta.AuthRequired || route.matched.find((d) => d.meta.AuthRequired)) {
        var user = localStorage.getItem('rideeat');
        if (!user) {
          return;
        }
        user = decodeURIComponent(user);
        user = JSON.parse(user);
        store.commit('setUser', user);
      }
    })
  }
})

</script>


<style>
.tab-ul-wrapper .router-link-exact-active{
    border-bottom: 3px solid #003087 !important;
    font-weight: 600 !important;
}
</style>

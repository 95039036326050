
import { defineComponent, onMounted, ref, computed } from "vue";
import OrdersTable from "@/components/orders/OrdersTable.vue";


export default defineComponent({
    name: "orders",
    setup() {

    },
    components: { OrdersTable }
});

import { userHasAccess } from "@/helpers/route";
import store from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import isNil from "lodash/isNil";

export const checkRolesAndPermissions = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.matched.some((record) => record.meta.AuthRequired)) {
    const sessionData = window.localStorage.getItem("rideeat");

    if (isNil(sessionData) || sessionData == "") {
      next({ name: "login" });
      return;
    } else {
      let roles = (to.meta && to.meta.roles ? to.meta.roles : []) as string[];

      let permissions = (
        to.meta && to.meta.permissions ? to.meta.permissions : []
      ) as string[];

      let hasAccess = userHasAccess(roles, permissions);

      if (hasAccess) {
        next();
      } else {
        next({ name: "unauthorized" });
      }
    }
    return;
  }

  next();
};

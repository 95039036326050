
import { useFood } from '@/ core/composables/useFood';
import { defineComponent, onMounted } from 'vue'
import Loader from '@/components/loader.vue';
import FoodsTable from '@/views/food/foods-table.vue'

export default defineComponent({
  components: { Loader, FoodsTable },

})

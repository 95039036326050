import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Transaction, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { useNotification } from "@kyvg/vue3-notification";
import { defaultTransaction, defaultResponseMeta } from '@/ core/data/orderData';

interface TransactionComposable {
    isLoading: Ref<boolean>,
    transactions:Ref<Transaction[]>,
    transaction: Ref<Transaction>,
    getTransaction: (uuid:any) => void;
    getTransactions: ({uuid, model, page, search}:Params) => void;
    paginationData: Ref<ResponseMeta>;
}

interface Params {
    uuid?:any,
    model?:any,
    page?:number,
    search?:any
}

export const useTransaction = (): TransactionComposable => {
    const isLoading = ref(false);
    const transaction: Ref<Transaction> = ref(cloneDeep(defaultTransaction) as unknown as Transaction);
    const transactions: Ref<Transaction[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getTransactions = async ({uuid, model, page=1, search=""}:Params) => {
        let response = model != undefined ?  await NetworkService.get('control-centre/get-transactions/'+uuid+'?page='+page+'&model='+model+'&search='+search)
        : await NetworkService.get('control-centre/transactions/?page='+page+'&search='+search)

        transactions.value = response.data.data.map((e:any)=>{
            e.extra = JSON.parse(e.extra)
            return e;
        })
        
        
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        
    }
    const getTransaction = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('control-centre/transactions/' + uuid);
        let data = response.data.data;

        data.extra =  JSON.parse(data.extra)
        
        transaction.value = data;
        isLoading.value = false;
    }


    return {
        isLoading,
        transaction,
        transactions,
        paginationData,
        getTransactions,
        getTransaction,                                                                                      
    }
}
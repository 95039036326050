
export default [
    {
        path: '',
        name: 'subscription.index',
        component: ()=> import("@/views/subscriptions/Index.vue"),
        meta: {
            permissions: ["subscription.index"],
        },
    },
    {
        path: 'create',
        name: 'subscription.create',
        component: ()=> import("@/views/subscriptions/Create.vue"),
        meta: {
            permissions: ["subscription.create"],
        },
    },
    {
        path: 'edit/:id',
        name: 'subscription.edit',
        component: ()=> import("@/views/subscriptions/Edit.vue"),
        meta: {
            permissions: ["subscription.edit"],
        },
    },
    // {
    //     path: 'view/:uuid',
    //     name: 'vendors.view',
    // },
]
import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';

interface ForgotPasswordComposable {
    loading:Ref<Boolean>
    sendEmail:(payload:String)=> Promise<Response>,
}
interface Response {
    message:string
    status:string
}
 export const useForgotPassword = ():ForgotPasswordComposable =>{
    const loading = ref(false);

    const sendEmail =async (email: String): Promise<Response>=>{
    const response = ref({message:'', status:'error'});
        loading.value=true;
        const userDetails = {"email":email};
        
      await  NetworkService.post("vendor/auth/forgot-password", userDetails).then((resp)=>{
            if (resp.status == 200){
                response.value.message = resp.data.message; 
                response.value.status = 'success'; 
            }
        }).catch((error)=>response.value.message = error.response.data.message);
        loading.value=false;
        return response.value;
    }
    return {
        loading,sendEmail,
    }
}

import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute, useRouter } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { useLocation } from '@/ core/composables/useLocation';
import Loader from '@/components/loader.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';
import AssignRider from '@/components/orders/AssignRider.vue';
import { assign } from 'lodash';
import DeleteModal from './DeleteModal.vue';

export default defineComponent({
    components: { TitledCard, Loader, NumberFormatter, AssignRider, DeleteModal },
    setup() {
        const router = useRouter();
        const { location, getLocation, isLoading, deleteLocation } = useLocation();

        const id = useRoute().params.id;
        
        onMounted(async () => {
            await getLocation(id);
            
        });
    
        const delLocation =async (location:any) => {
            
            await deleteLocation(location.id, location.action)
            if(location.action != 'area'){
                router.push({name: "location.index"})  
            }
            await getLocation(id);
        }

        return {
            location,
            isLoading,
            delLocation
        }
    }
})

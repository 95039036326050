import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Rider, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { useNotification } from "@kyvg/vue3-notification";
import { defaultRider, defaultResponseMeta } from '@/ core/data/orderData';


interface RiderComposable {
    isLoading: Ref<boolean>,
    riders:Ref<Rider[]>,
    rider: Ref<Rider>,  
    getRider: (uuid:any) => void;
    getRiders: (page?:number, search?:any) => void;
    getAvailableRiders: (order_id:any) => void,
    approveOrRejectRider: (uuid:any, action:string, message?:any) => void;
    paginationData: Ref<ResponseMeta>;
}

export const useRider = (): RiderComposable => {
    const isLoading = ref(false);
    const rider: Ref<Rider> = ref(cloneDeep(defaultRider) as unknown as Rider);
    const riders: Ref<Rider[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);
    const notification = useNotification();

    const getRiders = async (page:number = 1, search?:any) => {
        
        let response = await NetworkService.get('control-centre/riders?page='+page+'&search='+search);
        
        riders.value = response.data.data.map((e:any)=>{
            // e.area =  JSON.parse(e.area)
            // e.town =  JSON.parse(e.town) 
            return e;
        })
        
        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        
    }

    const getRider = async (uuid:any) => {
        isLoading.value = true;
        let response = await NetworkService.get('control-centre/riders/' + uuid);
        
        let data = response.data.data;

        data.docs =  JSON.parse(data.docs)
        
        // data.town =  JSON.parse(data.town) 
        
        rider.value = data;
        isLoading.value = false;
    }

    const approveOrRejectRider = async (uuid:any, action:string, message:any={}) => {
        isLoading.value = true;
        let response = await NetworkService.post('control-centre/rider/' + uuid+'/approve-or-reject?action='+action, message);
        if(response.status ==200){ 
            await getRider(uuid); 
            notification.notify({
                type: 'success',
                title: "Status",
                text: response.data.message,
            }) }
        else{
            notification.notify({
                type: 'error',
                title: "Status",
                text: response.data.message,
            })
        }
        isLoading.value = false;
    }

    const getAvailableRiders = async (order_id:any) => {
        isLoading.value = true;
        
        try {
            let response = await NetworkService.get('control-centre/get-available-riders/'+order_id);
        
            riders.value = response.data.data.map((e:any)=>{
                return e;
            })
        } catch (error) {
            // notification.notify({
            //     type: 'error',
            //     title: "Status",
            //     text: 'Request failed',
            // })
        }
         
        isLoading.value = false
    }


    return {
        isLoading,
        rider,
        riders,
        paginationData,
        getRiders,
        getRider,  
        getAvailableRiders,
        approveOrRejectRider                                                                                    
    }
}

import { defineComponent, onMounted, ref, computed, PropType } from "vue";
import type { Header, Item, ClickRowArgument } from "vue3-easy-data-table";
import { Transaction } from '@/models';
import { useTransaction } from '@/ core/composables/useTransaction';
import { useRouter } from "vue-router";
import NumberFormatter from "@/components/NumberFormatter.vue";
import TransactionItem from "./transaction-item.vue";
import Spinner from "@/components/Spinner.vue";


export default defineComponent({
    name: "transactions",
    props: {
        uuid: {
            type: String,
            default: '',
            required: false
        },
        model: {
            type: String,
            default: ""
        },
    },
    setup(props) {
        const router = useRouter();
        const { transactions, getTransactions, paginationData, isLoading } = useTransaction();
        const headers = [
            { text: "User", value: "wallet" },
            { text: "Type", value: "type" },
            { text: "Amount", value: "amount" },
            { text: "Description", value: "description" },
            { text: "Date", value: "created_at" }
        ];

        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const items = ref([] as unknown as Transaction[]);
        const search = ref("")

        const fetchTransactions = async (page?: number) => {
            if (props.uuid != null && props.model != ''){
                await getTransactions({uuid:props.uuid, model:props.model, page:page, search:search.value});
            }
            else{
                await getTransactions({page:page, search:search.value});
            }
            
            
            items.value = transactions.value;
            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
        };

        onMounted(async () => {
            fetchTransactions(1);
        });

        const searchMethod = () => {
            fetchTransactions(1)
        }

        const onClickRow = (row: ClickRowArgument) => {
            router.push({ name: "transactions.show", params: { uuid: row.uuid } });
        };

        const nextPage = (page: number) => {
            fetchTransactions(page);
        };

        const prevPage = (page: number) => {
            fetchTransactions(page);
        };

        return {
            headers,
            items,
            paginationData,
            isFirstPage,
            isLastPage,
            nextPage,
            prevPage,
            next,
            prev,
            onClickRow,
            transactions,
            search,
            searchMethod,
            isLoading
        };
    },
    components: { NumberFormatter, TransactionItem, Spinner }
});

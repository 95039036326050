
import { defineComponent, onMounted, ref, computed } from "vue";
import locationTable from "@/views/location/location-table.vue";


export default defineComponent({
  components: { locationTable },
    name: "location",
    setup() {
        
    },
});

import NetworkService, { setBearerToken }from '../../ core/services/network-service';
import type { User as ModelUser } from "@/models";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from '../enums/StoreEnums';

export interface User {
    token?: string | null;
    user: ModelUser;
    password?: string;
}

export interface UserAuthInfo {
    user: User;
}
export interface Error{
    status: boolean;
    message:string
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {} as unknown as Error;
    
    user = {
        token: null,
        user: {
            uuid: "",
            email: "",
            address: "",
            city: "",
            country: "",
            lattitude: "",
            longitude: "",
            local_government: "",
            phone: "",
            store_name: "",
            street_name: "",
            status: "",
            info: {},
            roles: [],
            permissions: [],
        },
        password: "",
    } as unknown as User;

    /**
       * Get current user object
       * @returns User
       */
    get currentUser() {
        var data = localStorage.getItem('rideeat') ?? '{}';
        data = decodeURIComponent(data);

        return JSON.parse(atob(data) || '{}') as ModelUser;
    }

    get getErrors() {
        return this.errors;
    }

    get userRoles() {
        return this.user?.user?.roles || [];
    }

    get userRolesName() {
        return this.userRoles.map((item) => item.name);
    }

    get userRolesPermissions() {
        return this.userRoles
        .map((role) => role.permissions.map((permission) => permission))
        .flat();
    }

    get userRolesPermissionsName() {
        return this.userRolesPermissions.map((item) => item.name);
    }

    get userDirectPermissions() {
        return this.user?.user?.permissions || [];
    }
    
    get userDirectPermissionsName() {
        return this.userDirectPermissions.map((item) => item.name);
    }
    
    get userAllPermissionsName() {
        return this.userDirectPermissionsName.concat(this.userRolesPermissionsName);
    }

    @Mutation
    [Mutations.SET_USER](user: ModelUser) {
        this.user.user = user;
        this.errors = {} as Error;
    }

    @Mutation
    [Mutations.PURGE_USER]() {
        window.localStorage.removeItem('rideeat');
        this.user = {} as User;
    }
    @Mutation
    [Mutations.SET_ERROR](response:any) {
        this.user.user = response;
        // this.errors = {status: response.status, message:response.data.message, response:response} as Error;
    }

    @Action
    async [Actions.LOGIN](credentials: any) {
        try {
            const { data, status} = await NetworkService.post("control-centre/auth/access", credentials);
            if (status == 200) {
                this.context.commit(Mutations.SET_USER, data.data);
                let result = btoa(JSON.stringify(data.data));
                localStorage.setItem('rideeat', result);
                localStorage.setItem('auth_token', data.token);
                setBearerToken();
            }
            return {status, data}
        } catch ({ response }: any) {
            this.context.commit(Mutations.SET_ERROR, response);
        }
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_USER);
    }
}
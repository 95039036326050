
import { defineComponent, onMounted, ref, computed, PropType } from "vue";
import type { Header, Item, ClickRowArgument } from "vue3-easy-data-table";
import { Order } from '@/models';
import { useOrder } from '@/ core/composables/useOrder';
import { useRouter } from "vue-router";
import NumberFormatter from "@/components/NumberFormatter.vue";
import OrderItem from "@/views/orders/order-item.vue";
import Spinner from "@/components/Spinner.vue";


export default defineComponent({
    name: "orders",
    props: {
        uuid: {
            type: String,
            default: '',
            required: false
        },
        model: {
            type: String,
            default: ""
        },
    },
    setup(props) {
        
        const router = useRouter();
        const { orders, getDeliveries, paginationData, isLoading } = useOrder();
        const headers = [
            { text: "Order", value: "order" },
            { text: "Vendor", value: "vendor" },
            { text: "Processing time", value: "time" },
            // { text: "Delivery Method", value: "delivery_method" },
            { text: "Delivery Fee", value: "delivery_fee" },
            { text: "Delivery From", value: "delivery_from" },
            { text: "Delivery To", value: "delivery_to" },
            { text: "Total (₦)", value: "sub_total" },
            { text: "Status", value: "status_text", sortable: true }];

        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const items = ref([] as unknown as Order[]);
        const search = ref("")

        const fetchOrders = async (page?: number) => {
            await getDeliveries({page:page, search:search.value})

            items.value = orders.value;
            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
        };

        onMounted(async () => {
            fetchOrders();
        });

        const searchMethod = () => {
            fetchOrders(1)
        }

        const onClickRow = (row: ClickRowArgument) => {
            router.push({ name: "viewOrder", params: { uuid: row.uuid } });
        };

        const nextPage = (page: number) => {
            fetchOrders(page);
        };

        const prevPage = (page: number) => {
            fetchOrders(page);
        };

        const pendingOrders = computed(()=> orders.value.filter((e)=> e.status <= 15))
        const OtherOrders = computed(()=> orders.value.filter((e)=> e.status > 15))

        return {
            headers,
            items,
            paginationData,
            isFirstPage,
            isLastPage,
            nextPage,
            prevPage,
            next,
            prev,
            pendingOrders,
            OtherOrders,
            onClickRow,
            searchMethod,
            search,
            isLoading
        };
    },
    components: { NumberFormatter, OrderItem, Spinner }
});

import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { Location, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { useNotification } from "@kyvg/vue3-notification";
import { defaultLocation, defaultResponseMeta } from '@/ core/data/orderData';

interface LocationComposable {
    isLoading: Ref<boolean>,
    locations:Ref<Location[]>,
    location: Ref<Location>,
    getLocation: (id:any) => void;
    getLocations: (page?:number, search?:any) => void;
    paginationData: Ref<ResponseMeta>;
    create: (data: any) => void;
    deleteLocation: (id:any, action:any) => void;
}

export const useLocation = (): LocationComposable => {
    const isLoading = ref(false);
    const location: Ref<Location> = ref(cloneDeep(defaultLocation) as unknown as Location);
    const locations: Ref<Location[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);
    const notification = useNotification();

    const create = async (data:any) => {
        isLoading.value = true
        let response = await NetworkService.post('/control-centre/add-location', data);
        if(response.status ==200){
            notification.notify({
                type: 'success',
                text: response.data.message,
            })
            
        }
        else{
            notification.notify({
                type: 'error',
                title: "Status",
                text: response.data.message,
            })
        }
        isLoading.value = true
    }

    const getLocations = async (page:number=1, search?:any) => {
        isLoading.value = true
        let response = await NetworkService.get('operations/towns?page='+page+'&search='+search);
        
        locations.value = response.data.data
        isLoading.value = false

        let meta = {...response.data.links, ...response.data.meta}
        paginationData.value = meta
        
    }

    const getLocation = async (id:any) => {
        isLoading.value = true
        let response = await NetworkService.get('control-centre/get-town/'+id);
        
        location.value = response.data.data
        isLoading.value = false
    }

    const deleteLocation =async (id:any, action:any) => {
        isLoading.value = true
                
        let response = action == 'area' ? await NetworkService.delete('control-centre/delete-area/'+id) 
        : action == 'town' ? await NetworkService.delete('control-centre/delete-town/'+id) 
        : await NetworkService.delete('control-centre/delete-state/'+id) 
        
        if(response.status ==200){
            notification.notify({
                type: 'success',
                text: response.data.message,
            })  
        }
        
        isLoading.value = false
    }


    return {
        isLoading,
        location,
        locations,
        paginationData,
        getLocations,
        getLocation,
        create,
        deleteLocation                                                                                    
    }
}

import { defineComponent } from 'vue';

export default defineComponent({
    props:{
        amount: {type: Number, required:true}
    },
    setup(props){
        const amount = Intl.NumberFormat('en-NG', { style: "currency", currency: "NGN" }).format(props.amount);
        return {
            amount
        }
    }
})

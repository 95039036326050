
import TitledCard from '@/components/cards/TitledCard.vue';
import HandleDocument from '@/components/HandleDocument.vue';
import DeliveryFee from "@/components/settings/DeliveryFee.vue"
import FileUploader from '@/components/forms/FileUploader.vue';
import AppBanners from '@/components/settings/AppBanners.vue';

export default {
    name: "uplaod-image",
    components: {
        HandleDocument,
        TitledCard,
        DeliveryFee,
        "file-uploader": FileUploader,
        AppBanners
    },
    setup() {
        return {
           
        };
    },
};

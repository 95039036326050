
import Vue, { defineComponent, onMounted, Ref, watch } from 'vue'
import { ref } from "vue";
import NetworkService from "@/ core/services/network-service";
import { useNotification } from "@kyvg/vue3-notification";
import TitledCard from '@/components/cards/TitledCard.vue';
import HandleDocument from '@/components/HandleDocument.vue';
import type { Banner } from '@/models';
import { cloneDeep } from "lodash";
import { defaultBanner } from '@/ core/data/orderData';
import DeliveryFee from "@/components/settings/DeliveryFee.vue"
import FileUploader from '@/components/forms/FileUploader.vue';


export default defineComponent({
    name: "uplaod-image",
    components: {
        HandleDocument,
        TitledCard,
        DeliveryFee,
        "file-uploader": FileUploader,
    },
    props: {
        bannerType: {
            type: String,
            required: true,
            default: 'app_carousel'
        },
    },
    setup(props:any) {
        const openBannerModal = ref(false);
        const noAction = ref(false);
        const notification = useNotification();
        const loading = ref(false);
        // banners will still be here and it will still be an array. but only one banner will be added at a time 
        const banners: Ref<Banner[]> = ref([]);
        const details: Ref<Banner> = ref(cloneDeep(defaultBanner) as unknown as Banner);
        const currentBanner = ref(null)
        const viewBanner = ref<Banner>({})

        const updateBanners = (fileurl: any) => {
            details.value = { ...details.value, "url": fileurl }
            // currentBanner.value = { ...fileurl };
        };

        watch(noAction, (v) => {
            if (v) {
                details.value.action = null
                details.value.value = ''
            }
        }, { deep: true })
        const addBanner = () => {

            if (details.value.url == '') {
                notification.notify({
                    type: 'error',
                    title: "Update Profile",
                    text: "Please upload a file",
                })
                return
            }
            banners.value.push(details.value)
            updateConfig();
        };
        const showBanner = (banner: Banner) => {
            openBannerModal.value = true;
            viewBanner.value = banner;
        }
        const updateConfig = async () => {

            let response = await NetworkService.post(
                "/control-centre/create-update-configuration", 
                { data: banners.value, title: props.bannerType }
                );

            if (response.status == 200) {
                notification.notify({
                    type: 'success',
                    title: "Update Profile",
                    text: response.data.message,
                })
                currentBanner.value = null;
            }
            else {
                notification.notify({
                    type: 'error',
                    title: "Update Profile",
                    text: response.data.message,
                })
            }
            details.value = {
                action: null,
                customName: '',
                name: '',
                url: '',
                value: '',
            }
            getBanners();
        };
        const getBanners = async () => {
            loading.value = true;
            let response = await NetworkService.get("/control-centre/get-configuration?title=" + props.bannerType);
            if (response.status == 200) {
                banners.value = response.data;

                loading.value = false;
                return
            }
            loading.value = false;
            notification.notify({
                type: 'error',
                title: "Unable to fetch banners",
                text: response.data.message,
            })
        }
        const switchPosition = (id: number, name: String) => {

            let item = banners.value[id];
            let itemPartner = null;
            let switchIndex = 0;

            switch (name) {
                case 'forward':
                    itemPartner = banners.value[id - 1];
                    switchIndex = id - 1;
                    break;
                default:
                    itemPartner = banners.value[id + 1];
                    switchIndex = id + 1;
                    break;
            }
            banners.value[id] = itemPartner;
            banners.value[switchIndex] = item
            updateConfig();
        }
        const popBanners = (id: number) => {
            banners.value = banners.value.filter((item, idx) => idx != id);
            updateConfig();
        }

        onMounted(() => {
            getBanners();
        })
        return {
            viewBanner,
            showBanner,
            openBannerModal,
            loading,
            popBanners,
            switchPosition,
            banners,
            updateBanners,
            updateConfig,
            details,
            addBanner,
            noAction,
            currentBanner,
        };
    },
});

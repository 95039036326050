import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "font-semibold text-[#1E2234] text-sm whitespace-wrap" }
const _hoisted_3 = { class: "text-[#636366] text-xs whitespace-nowrap" }
const _hoisted_4 = { class: "text-xs whitespace-nowrap" }
const _hoisted_5 = { class: "text-[#1E2234] text-sm font-semibold whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'transactions.show', params: { uuid: _ctx.transaction.uuid } },
    class: "flex justify-between items-start mb-7"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, [
            _createVNode(_component_NumberFormatter, {
              amount: _ctx.transaction.balance_after
            }, null, 8, ["amount"])
          ]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.transaction.type), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.transaction.description), 1)
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_5, [
          _createVNode(_component_NumberFormatter, {
            amount: _ctx.transaction.balance_after
          }, null, 8, ["amount"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
import store from "@/store";
import isNil from "lodash/isNil";

export function userHasAccess(roles: string[], permissions: string[]) { 
  
  const data = getRoleAndPermission();
  
  if(data == null) return false;
 
  const {userRolesName, userAllPermissionsName} = data

  if (userRolesName.includes("super-admin")) return true;

  if (roles.length == 0 && permissions.length == 0) return true;

  let hasAccess = false;
  if (roles.length) {
    hasAccess = roles.some((role) =>
      userRolesName.includes(role)
    );
    if (hasAccess) return true;
  }

  if (permissions.length) {
    hasAccess = permissions.some((permission) =>
      userAllPermissionsName.includes(permission)
    );
    if (hasAccess) return true;
  }

  return hasAccess;
}

const getRoleAndPermission = () =>{
  const sessionData = window.localStorage.getItem("rideeat");
  if (isNil(sessionData) || sessionData == "") {
    return null
  }

  const data = JSON.parse(atob(sessionData));
  const userRolesName = data.roles.map((e:any)=> e.name);
  let userAllPermissionsName = data.permissions.map((e:any)=> e.name)
  userAllPermissionsName = userAllPermissionsName.concat(data.roles.map((e:any)=> e.permissions.map((p:any)=> p.name)).flat())

  return {userRolesName, userAllPermissionsName}
}

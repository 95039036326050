
import { useFood } from '@/ core/composables/useFood';
import { defineComponent, onMounted, ref } from 'vue'
import Loader from '@/components/loader.vue';
import { Food } from '@/models';
import { useRouter } from "vue-router";
import type { Header, Item, ClickRowArgument } from "vue3-easy-data-table";
import NumberFormatter from "@/components/NumberFormatter.vue";
import { indexOf } from 'lodash';

export default defineComponent({
    components: { Loader, NumberFormatter },
    props: {
        uuid: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const router = useRouter();
        const { foods, getFoods, loading, paginationData } = useFood();
        const headers = [
            { text: "Image", value: "image" },
            { text: "name", value: "name" },
            { text: "Price", value: "price" },
            { text: "Preparation time", value: "preparation_time" },
            { text: "Restaurant", value: "restaurant" },
        ];

        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const items = ref([] as unknown as Food[]);
        const search = ref("")

        const fetchFoods = async (page?: number) => {
            if (props.uuid != null) {
                await getFoods({ page: page, uuid: props.uuid, search: search.value });
            }
            else {
                await getFoods({ page: page, search: search.value });
            }

            items.value = foods.value;
            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
        };

        const searchMethod = () => {
            fetchFoods()
        }

        const onClickRow = (row: ClickRowArgument) => {
            router.push({ name: "ShowView", params: { uuid: row.uuid } });
        };

        const nextPage = (page: number) => {
            fetchFoods(page);
        };

        const prevPage = (page: number) => {
            fetchFoods(page);
        };


        onMounted(() => {
            fetchFoods()

        })


        return {
            loading,
            foods,
            onClickRow,
            nextPage,
            prevPage,
            headers,
            items,
            paginationData,
            isFirstPage,
            isLastPage,
            next,
            prev,
            searchMethod,
            search
        }
    },
})

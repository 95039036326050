

interface DetailsInterface {
    title:string,
    value:number
}

import { defineComponent } from 'vue';
import DecimalFormatter from './DecimalFormatter.vue';

export default defineComponent({
    components:{
        DecimalFormatter
    },
    props:{

        details: {
            type: Object,
            required:true
        },

    },
    setup(props) {
        const details = props.details as unknown as DetailsInterface;

        return{
            details
        }
    },
})

<template>
 <router-view></router-view>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return { }
  },
})
</script>


<style></style>

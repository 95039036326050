
import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { useOrder } from '@/ core/composables/useOrder';
import Loader from '@/components/loader.vue';
import NumberFormatter from '@/components/NumberFormatter.vue';
import AssignRider from '@/components/orders/AssignRider.vue';
import { assign } from 'lodash';
import CancelModal from './CancelModal.vue';

export default defineComponent({
    components: { TitledCard, Loader, NumberFormatter, AssignRider, CancelModal },
    setup() {

        const { isLoading, order, cancelOrder, completeOrder,
            getOrder, acceptOrder, rejectOrder, readyForPickup, assignRider } = useOrder();

        const uuId = useRoute().params.uuid;
        const rejectionNote = ref('out of stock');
        
        onMounted(async () => {
            await getOrder(uuId);
        });
    
        const acceptOrderHandler = async () => {
            await acceptOrder(uuId);
        }

        const rejectOrderHandler = async () => {
            await rejectOrder(uuId, rejectionNote.value);
        }

        const processOrder = async () => {
            await readyForPickup(uuId);
        }

        const assignRiderToOrder = async (data: any) => {
            await assignRider(data, uuId)
        }

        const cancelOrderHandler = async (data: any) => {
            await cancelOrder(data, uuId)
        }

        const completeOrderHandler = async (data: any) => {
            await completeOrder(uuId)
        }

        return {
            order,
            isLoading,
            processOrder,
            acceptOrderHandler,
            rejectOrderHandler,
            assignRiderToOrder,
            cancelOrderHandler,
            completeOrderHandler
        }
    }
})

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-xl bg-white sm:w-fit w-full min-w-[200px] px-4 py-2 sm:mx-2 drop-shadow my-2" }
const _hoisted_2 = { class: "text-[#898989] text-sm whitespace-nowrap capitalize" }
const _hoisted_3 = { class: "grayText font-semibold text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DecimalFormatter = _resolveComponent("DecimalFormatter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.details.title), 1),
    _createElementVNode("p", _hoisted_3, [
      _createVNode(_component_DecimalFormatter, {
        amount: _ctx.details.value
      }, null, 8, ["amount"])
    ])
  ]))
}
<template>

    <div
        className="lg:w-[90%] w-[100%] lg:pl-28 sm:pl-5 pl-3 lg:pr-24 sm:pr-7 pr-2 ml-auto h-[100px] fixed top-0 right-0 flex justify-between items-end bg-white lg:pb-3 pb-5 z-50 border-b-2 border-[#FEF8F0]">
        <div class="w-full flex justify-between lg:justify-end  nav_wrap">
            <span class="lg:hidden block py-2 sidebar-btn cursor-pointer mr-1" @click="openSideBar">
                <i class="fa-solid fa-bars text-[#003087] text-3xl"></i>
            </span>
            <div class="flex justify-end items-center personal_info">
                <div class="motification relative lg:mr-14 md:mr-14 sm:mr-5 mr-3  z-0">
                    <span
                        class="bg-[#17C85F] absolute sm:-top-5 -top-3 sm:left-1 left-2 font-[Inter] rounded-full sm:w-[20px] w-[18px] sm:h-[20px] h-[18px] flex justify-center items-center text-white  text-xs">0
                    </span>
                    <img src="../assets/images/Notification.svg" class="sm:w-[21px] max-w-[15px] cursor-pointer"
                        alt="Notification" />
                </div>


                <div class="relative" data-te-dropdown-ref>
                    <button class="flex justify-end items-center" type="button" id="dropdownMenuButton1"
                        data-te-dropdown-toggle-ref aria-expanded="false" data-te-ripple-init data-te-ripple-color="light">
                        <div 
                            :style="{backgroundImage: 'url('+user.logo_image+')', backgroundSize:'cover', backgroundColor: 'lightgray'}"
                            class="border border-[#1E2234] rounded-full w-[45px] h-[45px] mr-2">
                            <!-- <img src="user.logo_image" alt="avatar" class="object-contain w-full h-full" /> -->
                        </div>
                        <div>
                            <!-- <p class="font-bold text-[#1E2234] text-left">{{ user.email }}
                            </p> -->
                            <p class="text-[#636366] text-xs">{{ user.email }}</p>
                        </div>
                        <span class="">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
                                <path fill-rule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clip-rule="evenodd" />
                            </svg>
                        </span>
                    </button>
                    <ul class="absolute z-[1000] border-none float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                        aria-labelledby="dropdownMenuButton1" data-te-dropdown-menu-ref>
                        <li>
                            <router-link to='/edit-address'
                                class="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                data-te-dropdown-item-ref> Change business address
                           </router-link>
                        </li>
                        <li>
                            <router-link to='/upload-image'
                                class="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                data-te-dropdown-item-ref> Upload image
                           </router-link>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import { computed, defineComponent } from "@vue/runtime-core"
import { useStore } from "vuex";
import { useToggle } from "@/ core/composables/useToggle";

export default defineComponent({
    name: 'navbar',

    setup() {
        const {open:openSideBar, close} = useToggle('sidebar', 'layer');
        const store = useStore();
        const user = computed(() => store.getters.currentUser);

        return { user, openSideBar }

    }
});
</script>

<style scoped>
.nav_wrap {
    padding-left: 5rem;
}

@media only screen and (max-width:1023px) {
    .nav_wrap {
        padding-left: 0rem;
    }
}</style>
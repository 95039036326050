import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Photos from '@/views/Photos.vue';
import Order from '@/views/orders/order.vue';
import CallDelivery from '@/views/Call-delivery.vue';
import Promotions from '@/views/Promotions.vue';
import Settings from '@/views/Settings.vue';
import BarChart from '@/views/BarChart.vue';
import Login from '@/views/auth/login.vue';
import Index from '@/views/index.vue';
import ForgotPassword from '@/views/auth/forgotPassword.vue';
import FoodIndex from '@/views/food/index.vue';
import CreateFood from '@/views/food/create.vue';
import EditFood from '@/views/food/edit.vue';
import ViewFood from '@/views/food/view.vue';
import ShowView from '@/views/food/show.vue';
import EditAddress from '@/views/EditAddress.vue';
import UplaodImage from '@/views/UploadImage.vue';
import OrderIndex from '@/views/orders/index.vue';
import Orders from '@/views/orders/orders.vue';
import Vendor from '@/router/vendor';
import Rider from '@/router/rider'
import Customer from '@/router/customer';
import Transactions from '@/views/transactions/transactions.vue';
import TransactionView from '@/views/transactions/transaction.vue';
import Deliveries from "@/views/deliveries/deliveries.vue"
import Location from "@/views/location/Index.vue";
import LocationView from "@/views/location/location.vue"
import Subscriptions from '@/router/subscriptions';
import CompletedOrders from '@/views/orders/completed-orders.vue';
import OngoingOrders from '@/views/orders/ongoing-orders.vue';
import CanceledOrders from '@/views/orders/canceled-orders.vue';
import PendingOrders from '@/views/orders/pending-orders.vue';
import TransactionFee from "@/router/transaction_fee"
import { checkRolesAndPermissions } from "./middlewares";
import Unauthorized from "@/views/UnathorizedView.vue";

const routes = [
  {
    path: '',
    name: 'home',
    redirect: "login"
  },
  {
    path: "/dashboard",
    name: "layout",
    component: Index,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/photos',
        name: 'photos',
        component: Photos
      },
      {
        path: '/foods',
        name: 'foodIndex',
        component: FoodIndex,
        children: [
          {
            path: '',
            name: 'foods',
            component: ViewFood,
            meta: {
              permissions: ["food.index"],
            },
          },
          {
            path: 'view/:uuid',
            name: 'ShowView',
            component: ShowView,
            meta: {
              permissions: ["food.view"],
            },
          }
        ]
      },
      {
        path: '/orders',
        name: 'orderIndex',
        component: OrderIndex,
        children: [
          {
            path: '',
            name: 'orders',
            component: Orders,
            meta: {
              permissions: ["order.index"],
            },
            children: [
              {
                path: 'completed',
                name: 'order.completed',
                component: CompletedOrders
              },
              {
                path: '',
                name: 'order.ongoing',
                component: OngoingOrders
              },
              {
                path: 'pending',
                name: 'order.pending',
                component: PendingOrders
              },
              {
                path: 'canceled',
                name: 'order.canceled',
                component: CanceledOrders
              },
            ]
          },
          {
            path: 'view/:uuid',
            name: 'viewOrder',
            component: Order,
            meta: {
                permissions: ["vendor.view"],
            },
          },
        ]
      },
      {
        path: '/call-delivery',
        name: 'call-delivery',
        component: CallDelivery
      },
      {
        path: '/promotions',
        name: 'promotions',
        component: Promotions
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: () => import("@/components/InnerLayout.vue"),

        children: [
          {
            path: '',
            name: 'transactions.index',
            component: Transactions,
            meta: {
              permissions: ["transaction.index"],
            },


          },
          {
            path: 'view/:uuid',
            name: 'transactions.show',
            component: TransactionView,
            meta: {
              permissions: ["transaction.view"],
          },

          }
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          permissions: ["setting.index"],
        },
      },
      {
        path: '/bar-chart',
        name: 'bar-chart',
        component: BarChart
      },
      {
        path: '/edit-address',
        name: 'edit-address',
        component: EditAddress,
        meta: {
          permissions: ["setting.edit-address"],
        },
      },
      {
        path: '/upload-image',
        name: 'upload-image',
        component: UplaodImage,
        meta: {
          permissions: ["setting.upload-image"],
        },
      },
      {
        path: '/vendors',
        component: () => import("@/components/InnerLayout.vue"),
        children: Vendor,
      },
      {
        path: '/riders',
        component: () => import("@/components/InnerLayout.vue"),
        children: Rider
      },
      {
        path: '/customers',
        component: () => import("@/components/InnerLayout.vue"),
        children: Customer
      },
      {
        path: '/deliveries',
        component: Deliveries,
        name: 'deliveries.index',
        meta: {
          permissions: ["delivery.index"],
        },
      },
      {
        path: '/locations',
        component: () => import("@/components/InnerLayout.vue"),
        name: 'locations',
        children: [
          {
            path: '',
            component: Location,
            name: 'location.index',
            meta: {
              permissions: ["location.index"],
            },
          },
          {
            path: 'view/:id',
            component: LocationView,
            name: 'location.view',
            meta: {
              permissions: ["location.view"],
            },
          }
        ]
      },
      {
        path: '/subscriptions',
        component: () => import("@/components/InnerLayout.vue"),
        name: 'subscriptions',
        children: Subscriptions
      },
      {
        path: '/transaction_fees',
        children: TransactionFee,
        component: () => import("@/components/InnerLayout.vue"),
      },
    ],
    meta: {
      AuthRequired: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword
  }
] as RouteRecordRaw[]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
})

export default router;

router.beforeEach(checkRolesAndPermissions);

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.AuthRequired)) {
    if (localStorage.getItem('rideeat') == null) {
      if (to.meta.AllowNoAuth) {
        next();
        return false
      }
      next({
        path: '/login',
        name: 'login'
      })
    }
  }
  next();
});
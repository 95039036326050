
import TitledCard from '@/components/cards/TitledCard.vue'
import { useFood } from '@/ core/composables/useFood';
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Loader from '@/components/loader.vue';
import Spinner from '@/components/Spinner.vue';
import { userHasAccess } from "@/helpers/route";

export default defineComponent({
    components: { TitledCard, Loader, Spinner },
               
    setup() { 
        const { food, getFood, loading } = useFood();
        const uuId = useRoute().params.uuid;

        onMounted( async () => { 
            getFood(uuId);
        });
          
    
        return {
            loading,
            food,
            userHasAccess
        }

    },
})

export default [
    {
        path: '',
        name: 'customers.index',
        component: ()=> import("@/views/customers/index.vue"),
        meta: {
            permissions: ["customer.index"],
        },
    },
    {
        path: 'view/:uuid',
        name: 'customers.view',
        component: ()=> import("@/views/customers/customer.vue"),
        children: [
            {
                path: '',
                name: 'customers.view.details',
                component: ()=> import("@/views/customers/customer-details.vue"),
                meta: {
                    permissions: ["customer.view"],
                },
            },
            {
                path: 'orders',
                name: 'customers.view.orders',
                component: ()=> import("@/views/customers/customer-orders.vue"),
                meta: {
                    permissions: ["customer.view.orders.index"],
                },
            },
            {
                path: 'subscriptions',
                name: 'customers.subscriptions',
                component: ()=> import("@/views/customers/customer-subscriptions.vue"),
                meta: {
                    permissions: ["customer.view.subscriptions.index"],
                },
            }
        ]
    },
]
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './assets/tailwind.css';
import './assets/css/dashboard.css';
import 'tw-elements';
import Vue from 'vue'
import Sortable from 'vue-sortable'

import store from "./store";
import Notifications from '@kyvg/vue3-notification'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import moment from 'moment'
import './assets/js/dashboard.js';
const app = createApp(App);

/**
 * @params {date} date to be converted to timeago
 * @returns returns timeAgo
 */
app.config.globalProperties.$filters = {
    formatDate(date:any) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  }
  
app.use(Sortable)

app.use(Notifications)
app.use(router)
app.use(store)
app.component("Multiselect", Multiselect)
app.component('EasyDataTable', Vue3EasyDataTable); 
app.mount('#app');
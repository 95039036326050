
import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { useRider } from '@/ core/composables/useRider';
import Loader from '@/components/loader.vue';
import { useNotification } from "@kyvg/vue3-notification";
import NumberFormatter from '@/components/NumberFormatter.vue';
import type { Order } from '@/models';

export default defineComponent({
    components: { TitledCard, Loader, NumberFormatter },
    props: {
        riderId: {
            type: Number,
            default: null
        },
        order: {
            type: Object as PropType<Order>
        }
    },
    emits: [
        'assignRider'
    ],
    setup(props, { emit }) {
        const uuId = useRoute().params.uuid;
        const notification = useNotification();
        const payload = ref({
            rider_id: props.riderId,
            order_id: uuId,
            automate: false
        });

        const { isLoading, riders, getAvailableRiders } = useRider();

        onMounted(async () => {
            await getAvailableRiders(props.order?.id);
        })

        const submit = ((automate: boolean) => {
            if (!automate && payload.value.rider_id == null) {
                notification.notify({
                    type: 'error',
                    title: "Status",
                    text: 'select a rider',
                })
                return
            }
            if (automate) {
                payload.value.automate = true
            }

            emit("assignRider", payload.value)
        })

        return {
            payload,
            submit,
            riders
        }
    }
})


import { defineComponent, onMounted, ref, PropType } from 'vue';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import type { DashboardDetails, ChartData } from '@/models';
import NetworkService from '@/ core/services/network-service';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default defineComponent({
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object as PropType<ChartData> ,
      required: true
    }
  },
  setup(props) {
    const chartData = {
      labels: props.chartData.labels,
      datasets: [
        {
          data: props.chartData.data,
          backgroundColor: '#E9E9E9',
          label: 'Data One',
          borderRadius: 8,
          borderSkipped: false,
          barPercentage: 0.8,
          borderWidth: 1,
          hoverBackgroundColor: '#003087',
          color: '#000000'
        }
      ]
    }
    const chartOptions = {
      responsive: true
    }
    const ticks = {
      major: {
        enabled: true
      },
    }


    return {
      chartData,
      chartOptions,
      ticks
    }
  },
})
